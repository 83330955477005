const headerBis = document.querySelector('#header');
const headerContactModal = document.querySelector('.header__contact');
const burgerToggle = document.querySelector('#burger-toggle');
const firstMenu = document.querySelector('#menu-menu-principal-gauche');
const secondMenu = document.querySelector('#menu-menu-principal-droite');
const bodyBis = document.querySelector('body');

const builBurgerMenu = () => {
  const burgerMenu = document.createElement('nav');
  const tmpFirst = firstMenu.cloneNode(true);
  const tmpSecond = secondMenu.cloneNode(true);
  tmpFirst.classList.remove('header__menu');
  tmpSecond.classList.remove('header__menu');

  tmpFirst.classList.add('burger-menu__menu');
  tmpSecond.classList.add('burger-menu__menu');

  burgerMenu.classList.add('burger-menu');
  burgerMenu.setAttribute('data-open', 'false');
  burgerMenu.setAttribute('id', 'burger-menu');
  burgerMenu.appendChild(tmpFirst);
  burgerMenu.appendChild(tmpSecond);
  headerBis.appendChild(burgerMenu);

  const burgerItemsSubmenu = headerBis.querySelectorAll('.menu-item');

  const openSubmenu = (item, list) => {
    item.classList.toggle('open');

    list.forEach((otherItem) => {
      otherItem !== item && otherItem.classList.remove('open');
    });
  };

  burgerItemsSubmenu.forEach((item) => {
    const link = item.querySelector('a');
    const subMenu = item.querySelector('.sub-menu');

    if (subMenu) {
      link.addEventListener('click', (event) => {
        event.preventDefault(); // Prevent default link behavior
        openSubmenu(item, burgerItemsSubmenu);
      });
    } else {
      link.addEventListener('click', () => {
        const isOpenContact = item.classList.contains('open-contact');
        isOpenContact &&
          headerContactModal.setAttribute('data-visible', 'true');
      });
    }
  });
};

const openMenu = () => {
  const burgerMenu = document.getElementById('burger-menu');
  const headerOrigin = headerBis.getAttribute('data-origin') === 'light';
  const isHeaderBottom = headerBis.classList.contains('header--btm');

  const currentScroll = window.scrollY;

  burgerToggle.setAttribute('aria-expanded', 'true');
  burgerMenu.setAttribute('data-open', 'true');

  if (headerOrigin) {
    currentScroll > 100 && headerBis.classList.add('header--light');
    currentScroll > 100 && headerBis.classList.remove('header--min');
    isHeaderBottom && headerBis.classList.remove('header--btm');
  } else {
    headerBis.classList.add('header--light');
    currentScroll > 100 && headerBis.classList.remove('header--min');
  }

  bodyBis.classList.add('no-scroll');
};

const closeMenu = () => {
  const burgerMenu = document.getElementById('burger-menu');
  const headerOrigin = headerBis.getAttribute('data-origin') === 'light';
  const isHeaderBottom = headerBis.classList.contains('header--btm');

  const currentScroll = window.scrollY;

  burgerToggle.setAttribute('aria-expanded', 'false');
  burgerMenu.setAttribute('data-open', 'false');

  if (headerOrigin) {
    currentScroll > 100 && headerBis.classList.remove('header--light');
    currentScroll > 100 && headerBis.classList.add('header--min');
    isHeaderBottom && headerBis.classList.add('header--btm');
  } else {
    headerBis.classList.remove('header--light');
    currentScroll > 100 && headerBis.classList.add('header--min');
  }

  bodyBis.classList.remove('no-scroll');
};

const removeBurgerMenu = () => {
  const burgerMenu = document.getElementById('burger-menu');
  closeMenu();
  headerBis.removeChild(burgerMenu);
};

burgerToggle.addEventListener('click', () => {
  const isOpen = burgerToggle.getAttribute('aria-expanded') === 'true';
  isOpen ? closeMenu() : openMenu();
});

window.addEventListener('resize', () => {
  const isBurgerMenu = window.innerWidth <= 992;
  const burgerMenu = document.querySelector('.burger-menu');

  if (isBurgerMenu) {
    if (burgerMenu) return;
    builBurgerMenu();
  } else {
    if (!burgerMenu) return;
    removeBurgerMenu();
  }
});

const isBurgerMenu = window.innerWidth <= 992;
isBurgerMenu && builBurgerMenu();
